import React, { Component } from 'react';
import Modal from 'react-modal';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../../shared/CBMediaQuery';
import '../../../App.css';
import { getStyle, getModalStyle } from '../../../utils/utils';
import CBButtonSubmit from '../../shared/CBButtonSubmit';
import CBInputField from '../../shared/form_components/CBInputField';
import CBTextAreaField from '../../shared/form_components/CBTextAreaField';
import {
  createVehicleMaintenance as _createVehicleMaintenance,
  updateVehicleMaintenance as _updateVehicleMaintenance,
} from '../../../actions/vehicleActions';
import {
  getNumberValidator,
  getLengthValidator,
} from '../../../utils/validators';
import CBDatePickerInput from '../../shared/CBDatePickerInput';
import { browserIsIE } from '../../../ie-support';

class AddMaintenanceModal extends Component {
  constructor(props) {
    super(props);

    const { maintenance, user, selectedWorkshopId } = props;

    this.state = {
      formData: {
        km: maintenance ? maintenance.km : '',
        name: maintenance ? maintenance.tasks[0].name : '',
        description: maintenance && maintenance.notes && maintenance.notes.length > 0 ? maintenance.notes[0].description : '',
        pickup_date: maintenance
          ? moment(maintenance.pickup_date).format('YYYY-MM-DDTHH:mm')
          : moment().format('YYYY-MM-DDTHH:mm'),
        vehicle_id: null,
        workshop_id: selectedWorkshopId,
      },
      formValid: {
        km: !!maintenance,
        name: !!maintenance,
        description: !!maintenance,
      },
      displayedDate: moment(),
    };
  }

  componentDidMount = () => {
    const { user, vehicle, selectedWorkshopId } = this.props;
    const { formData } = this.state;
    this.setState({
      formData: {
        ...formData,
        vehicle_id: vehicle.id,
        workshop_id: selectedWorkshopId,
      },
    });
  };

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
      [name]: isValid,
    };

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const { formData } = this.state;
    const {
      createVehicleMaintenance,
      updateVehicleMaintenance,
      maintenance,
      closeModal,
      vehicle,
      user,
      selectedWorkshopId
    } = this.props;
    const cleanedFormData = Object.assign({}, formData);
    cleanedFormData.notes = [{
      description: cleanedFormData.description,
    }];
    cleanedFormData.description = undefined;
    cleanedFormData.tasks = [{
      name: cleanedFormData.name,
    }];
    cleanedFormData.name = undefined;
    if (maintenance) {
      updateVehicleMaintenance(maintenance.id, cleanedFormData);
    } else {
      createVehicleMaintenance(cleanedFormData);
    }
    this.setState({
      formData: {
        km: maintenance ? maintenance.km : '',
        name: maintenance ? maintenance.name : '',
        description: maintenance ? maintenance.description : '',
        pickup_date: maintenance
          ? moment(maintenance.pickup_date).format('YYYY-MM-DDTHH:mm')
          : moment().format('YYYY-MM-DDTHH:mm'),
        vehicle_id: vehicle.id,
        workshop_id: selectedWorkshopId,
      },
      formValid: {
        km: !!maintenance,
        name: !!maintenance,
        description: !!maintenance,
      },
      displayedDate: moment(),
    });
    closeModal();
  };

  changeDate = value => {
    const { formData } = this.state;
    this.setState({
      formData: { ...formData, pickup_date: moment(value).format('YYYY-MM-DDTHH:mm') },
      displayedDate: value,
    });
  };

  render() {
    const { formData, displayedDate } = this.state;
    const {
      modalIsOpen,
      closeModal,
      afterOpenModal,
      t,
      maintenance,
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={getModalStyle(screenSize, styles.modal)}
            contentLabel='Add maintenance modal'
          >
            <div style={getStyle(screenSize, styles, 'container')}>
              <img
                src='/img/icon_close.svg'
                alt='close'
                style={getStyle(screenSize, styles, 'closeIcon')}
                onClick={closeModal}
              />
              <p style={getStyle(screenSize, styles, 'title')}>
                {maintenance
                  ? t('updateMaintenanceLabel')
                  : t('addMaintenanceLabel')}
              </p>
              <div style={getStyle(screenSize, styles, 'fieldsRow')}>
                <div style={getStyle(screenSize, styles, 'inputCol')}>
                  <CBInputField
                    name='km'
                    inputStyle={getStyle(screenSize, styles, 'input')}
                    value={parseInt(formData.km)}
                    type='number'
                    placeholder='Mittarilukema'
                    onChange={this.updateFormState}
                    validators={[
                      getNumberValidator(),
                      getLengthValidator(1, 8)
                    ]}
                    isValidCallback={this.setIsValid}
                    labelText={t('addMaintenanceKmLabel')}
                    labelTextStyle={getStyle(screenSize, styles, 'subTitle')}
                  />
                </div>
                <div style={getStyle(screenSize, styles, 'inputCol')}>
                  <p
                    className='font-titillium-web-semi-bold'
                    style={getStyle(screenSize, styles, 'subTitle')}
                  >
                    {t('addMaintenanceDateLabel')}
                  </p>
                  <div
                    style={getStyle(screenSize, styles, 'datePickerContainer')}
                  >
                    <CBDatePickerInput
                      dateFormat='dd.MM.yyyy HH:mm'
                      displayedDate={moment(formData.pickup_date)}
                      type='datetime'
                      changeDate={this.changeDate}
                    />
                  </div>
                </div>
              </div>
              <CBInputField
                name='name'
                inputStyle={browserIsIE() ? getStyle(screenSize, styles, 'inputIE') : getStyle(screenSize, styles, 'input')}
                value={formData.name}
                placeholder={t('maintenanceTitlePlaceholder')}
                onChange={this.updateFormState}
                validators={[getLengthValidator(4, 40)]}
                isValidCallback={this.setIsValid}
                labelText={t('addMaintenanceNameLabel')}
                labelTextStyle={getStyle(screenSize, styles, 'subTitle')}
              />
              <CBTextAreaField
                name='description'
                inputStyle={getStyle(screenSize, styles, 'descriptionInput')}
                value={formData.description}
                onChange={this.updateFormState}
                labelText={t('addMaintenanceDescriptionLabel')}
                labelTextStyle={getStyle(screenSize, styles, 'subTitle')}
                validators={[getLengthValidator(4, 5000)]}
                isValidCallback={this.setIsValid}
              />
              <CBButtonSubmit
                disabled={!this.getIsValid()}
                onClick={this.submitForm}
                text={t('maintenanceSaveButton')}
              />
            </div>
          </Modal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    closeIcon: {
      position: 'absolute',
      top: 8,
      right: 8,
      opacity: 0.2,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
    title: {
      fontSize: 32,
      color: '#0095da',
      textAlign: 'center',
    },
    subTitle: {
      fontSize: 14,
      color: 'black',
      textAlign: 'center',
      fontWeight: 600,
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 180,
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
    },
    inputIE: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 400,
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      border: 'none',
      marginBottom: 30,
      padding: 8,
    },
    select: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 180,
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      textAlignLast: 'center',
    },
    info: {
      width: '100%',
      fontSize: 14,
      color: 'black',
      paddingHorizontal: 30,
      textAlign: 'center',
    },
    fieldsRow: {
      display: 'flex',
      flexDirection: 'row',
      width: 400,
      justifyContent: 'space-between',
      alignItems: 'space-between',
    },
    kmInput: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 195,
    },
    descriptionInput: {
      width: '80%',
      height: 250,
      resize: 'none',
    },
    inputCol: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },
    fieldsRow: {
      display: 'flex',
      flexDirection: 'column',
      width: 250,
      justifyContent: 'space-between',
      alignItems: 'space-between',
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 200,
      resize: 'none',
      color: 'black',
      height: 30,
    },
    descriptionInput: {
      width: '90%',
      height: 150,
      resize: 'none',
    },
    datePickerContainer: {
      alignSelf: 'center',
      width: '75%',
      alignItems: 'center',
    },
  },
  modal: {
    default: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        maxHeight: '95vh',
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    medium: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    small: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '90%',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
};

function mapStateToProps(state) {
  return {
    vehiclePermissionRequest: state.vehiclePermission,
    user: state.authUser.user,
    vehicle: state.vehicle.vehicle,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    createVehicleMaintenance: _createVehicleMaintenance,
    updateVehicleMaintenance: _updateVehicleMaintenance,
  },
)(translate('VehicleDetailsView')(AddMaintenanceModal));
